<template>
  <div class="info">
    <span class="icon">
      <icon id="circle-info" />
    </span>
    <span class="text">
      <slot></slot>
    </span>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
.info
  text-decoration: none
  color: $info
  display: inline-flex
  align-items: center
  font-size: 1rem
  font-weight: bold
  margin: 0
  .text
    margin-bottom: 0
  .icon
    font-size: 3rem
    margin-bottom: 0
</style>
